import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from 'react-i18next';

const RecommendationSnippet = ({ text, isGrid }: { text: string, isGrid: boolean }) => {
  const { t } = useTranslation();
  const seeMore = document.createElement('p');
  seeMore.innerHTML = t('labels.see_more');
  seeMore.classList.add('float-right', 'text-red-200', 'underline');
  const textNode = new DOMParser().parseFromString(text, "text/html").body.firstChild?.firstChild as HTMLElement;
  
  if(textNode && isGrid) {
    const targetElement = textNode.querySelector('.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr'); // Replace '.target-class' with the actual class or selector of the target element
    if (targetElement) targetElement.appendChild(seeMore);
  }

  return (
    <div
      dangerouslySetInnerHTML={{ __html: textNode ? (textNode as HTMLElement).innerHTML : "" }}
    />
  );
};

RecommendationSnippet.propTypes = {
  text: PropTypes.string.isRequired,
  isGrid: PropTypes.bool.isRequired,
};

export default RecommendationSnippet;
