// eslint-disable-next-line no-unused-vars
import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useRecommendationsFetch } from "../../lib/api";
import { useSearchQuery } from "../../lib/custom_hooks";
// eslint-disable-next-line no-unused-vars
import Sidebar from "../Sidebar";
import FakeSpinner from "../common/FakeLoader";
import FilterTags from "../common/FilterTags";
// eslint-disable-next-line no-unused-vars
import InstructionModal from "../common/InstructionModal";
// eslint-disable-next-line no-unused-vars
import SearchInstructions from "../common/SearchInstructions";
import Search from "../common/Search";
import RecommendationGroupsList from "../recommendation/RecommendationGroupsList";
import { Link, useHistory } from "react-router-dom";
import ExternalLink from "../common/ExternalLink";

const RecommendationsPage = () => {
  const initialSearchQuery = useSearchQuery();
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const { currentFilters, data, isError, isLoading, onChangeFilter, onClearFilters } =
    useRecommendationsFetch("recommendations");

  const onRemoveFilter = useCallback(
    (filterKey) => {
      onChangeFilter(filterKey, "any");
    },
    [onChangeFilter]
  );

  const history = useHistory();
  useEffect(() => {
    history.push('/recommendations');
  }, [history]);
  return (
    <>
      <div className="bg-gray-100 overflow-hidden relative font-transat">
        <div
          className="relative
          h-full w-full overflow-hidden flex flex-col-reverse sm:flex-row justify-end"
        >
          <div className="w-full">
            <div
              id="search-and-tags-container"
              className="w-full bg-white shadow relative"
              style={{ zIndex: 5 }}
            >
              <div className="w-full  max-w-screen-lg mx-auto flex flex-col md:flex-row items-center">
                <Search
                  className="mt-8 mx-3 md:mt-1 mb-2"
                  searchQuery={initialSearchQuery}
                  onSearch={(query) => onChangeFilter("searchQuery", query)}
                />
                <div className="mt-2 flex flex-col md:flex-row">
                  <Link className="my-auto mx-3 text-red-200" to="/glossary">
                    {t("labels.glossary")}
                  </Link>
                  <ExternalLink className="my-auto mx-3 text-red-200" url={t("links.guidelines")}>
                    {t("labels.good_practises")}
                  </ExternalLink>
                </div>
              </div>
              <FilterTags currentFilters={currentFilters} onRemove={onRemoveFilter} />
            </div>
            <div className="flex-grow w-full max-w-screen-lg mx-auto">
              {isError && <div>{t("errors.fetching_failed")}</div>}
              <FakeSpinner isLoading={isLoading} interval={75} />
              {!isLoading && <RecommendationGroupsList recommendations={data?.recommendations} />}
            </div>
          </div>
          {/*isLoading || _.isEmpty(data) ? null : (
            <Sidebar
              isLoading={isLoading}
              filters={data.filterValues}
              currentFilters={currentFilters}
              onChangeFilter={onChangeFilter}
              onClearFilters={onClearFilters}
            />
          )*/}
        </div>
      </div>
    </>
  );
};

export default RecommendationsPage;
